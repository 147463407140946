import React, { useState } from "react";
import Carousel from "@brainhubeu/react-carousel";

const BasicsCarousel = () => {
  const [value, setValue] = useState(0);

  const onChange = (value) => {
    setValue(value);
  };

  const programmingBasicsInfo = [
    {
      heading: "GraphQl",
      info: `Intermediate knowledge of the data querying language, GraphQL.`,
      rating: "50%",
      color: "#c4c428",
      id: 1,
    },
    {
      heading: "HTML & CSS",
      info: `Solid understanding of the behaviour of HTML elements and best practices. Good general knowledge understanding of:
      CSS box model, CSS flexbox module, CSS grid layout module and @media rules implementation.`,
      rating: "90%",
      color: "#6cbb1d",
      id: 2,
    },
    {
      heading: "Javascript",
      info: `Good knowledge and experience using the scripting language Javascript.`,
      rating: "70%",
      color: "#a6bb1d",
      id: 3,
    },
    {
      heading: "Sass",
      info: `Good knowledge and experience of the preprocessor scripting language Sass.`,
      rating: "80%",
      color: "#85bb1d",
      id: 4,
    },

    {
      heading: "Typescript",
      info: `Intermediate knowledge of the strongly typed programming language Typescript.`,
      rating: "40%",
      color: "#d1d13d",
      id: 5,
    },
  ];
  console.log(Carousel);
  return (
    <>
      <Carousel
        arrows
        value={value}
        onChange={onChange}
        animationSpeed={100}
        minDraggableOffset={0.1}
        itemWidth={409}
        infinite={false}
        draggable={true}
        breakpoints={{
          400: {
            itemWidth: 300,
            arrows: false,
          },
          500: {
            itemWidth: 360,
            arrows: false,
          },
          700: {
            itemWidth: 400,
            arrows: false,
          },
          800: {
            arrows: false,
          },
        }}
      >
        {programmingBasicsInfo.map((data) => (
          <div className="programming-card" key={data.id}>
            <div className="heading-box">
              <h3 className="scndry-heading">{data.heading}</h3>
              <div
                className="info-graphic"
                style={{
                  color: data.color,
                  border: `solid 3px ${data.color}`,
                }}
              >
                <span className="label-medium">{data.rating}</span>
              </div>
            </div>
            <p className="scndry-text">{data.info}</p>
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default BasicsCarousel;
