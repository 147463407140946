import React, { useState } from "react";
import Carousel from "@brainhubeu/react-carousel";

const LibsFrameworksCarousel = () => {
  const [value, setValue] = useState(0);

  const onChange = (value) => {
    setValue(value);
  };

  const programmingLibsFrameworksInfo = [
    {
      heading: "Angular",
      info: `Good understanding of components, templates, directives, services, modules and lifecycle hooks.`,
      rating: "60%",
      color: "#b7c428",
      id: 1,
    },
    {
      heading: "Bootstrap",
      info: `Good knowledge of the CSS framework Bootstrap.`,
      rating: "70%",
      color: "#a6bb1d",
      id: 2,
    },
    {
      heading: "Gatsby.js",
      info: `Good fundamental understanding of Gatsby.js. Experience using Gatsby-image-plugin. Experience using Gatsby with Wordpress as a headless cms coupled with Netlify for continous deployment.`,
      rating: "75%",
      color: "#a6bb1d",
      id: 3,
    },
    {
      heading: "React.js",
      info: `Good understanding and experience working with class components, lifecycle methods, functional components and React hooks. Decent understanding and experience working with Context API.`,
      rating: "75%",
      color: "#a6bb1d",
      id: 4,
    },
    {
      heading: "React native",
      info: `Intermediate knowledge and experience working with React native.`,
      rating: "50%",
      color: "#c4c428",
      id: 5,
    },
    {
      heading: "RxJs",
      info: `Basic understanding of the reactive programming library RxJs.`,
      rating: "40%",
      color: "#d1d13d",
      id: 6,
    },
  ];

  return (
    <>
      <Carousel
        arrows
        value={value}
        onChange={onChange}
        animationSpeed={100}
        minDraggableOffset={0.1}
        itemWidth={409}
        infinite={false}
        draggable={true}
        breakpoints={{
          400: {
            itemWidth: 300,
            arrows: false,
          },
          500: {
            itemWidth: 360,
            arrows: false,
          },
          700: {
            itemWidth: 400,
            arrows: false,
          },
          800: {
            arrows: false,
          },
        }}
      >
        {programmingLibsFrameworksInfo.map((data, i) => (
          <div className="programming-card" key={data.id}>
            <div className="heading-box">
              <h3 className="scndry-heading">{data.heading}</h3>
              <div
                className="info-graphic"
                style={{
                  color: data.color,
                  border: `solid 3px ${data.color}`,
                }}
              >
                <span className="label-medium">{data.rating}</span>
              </div>
            </div>
            <p className="scndry-text">{data.info}</p>
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default LibsFrameworksCarousel;
