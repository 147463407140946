import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

// Carousels

import BasicsCarousel from './carousels/basicsCarousel';
import LibsFrameworksCarousel from './carousels/libsFrameworksCarousel';
import MiscellanousCarousel from './carousels/miscellaneousCarousel';
import ProjectFernweh from '../../../../components/cards/projects/Fernweh';
import ProjectRosa from '../../../../components/cards/projects/Rosa-olof';
import ProjectWotsch from '../../../../components/cards/projects/Wotsch';

const Qualifications = () => {
	const [handleAnimations, setHandleAnimations] = useState(false);

	// Intersection observer that set's the inView state when 15% of the referenced element is hidden/insight.

	const { ref, inView } = useInView({
		threshold: 0.15,
	});

	// Handle triggering animations.

	useEffect(() => {
		if (inView) {
			setHandleAnimations(true);
		}
	}, [handleAnimations, inView]);

	return (
		<section id='qualifications-section'>
			{/* Mobile/tablet heading */}
			<div className='hero-heading-box'>
				<h2 className='hero-heading-smaller'>Qualifi</h2>
				<h2 className='hero-heading-smaller part-two'>-cations</h2>
			</div>
			{/* desktop heading */}
			<div className='hero-heading-box-desktop'>
				<h2 className='hero-heading-smaller part-two'>Qualifications</h2>
			</div>

			{/* Programming  */}

			<div id='programming' ref={ref}>
				<h2 className='main-heading'>Programming</h2>

				<div className={handleAnimations ? 'card-container' : 'unactive'}>
					<h2 className='category-heading'>Languages</h2>
					<BasicsCarousel />
				</div>

				<div className={handleAnimations ? 'card-container' : 'unactive'}>
					<h2 className='category-heading'>Frameworks and libraries</h2>
					<LibsFrameworksCarousel />
				</div>

				<div className={handleAnimations ? 'card-container' : 'unactive'}>
					<h2 className='category-heading'>Miscellaneous</h2>
					<MiscellanousCarousel />
				</div>
			</div>

			{/* Projects */}

			<section id='projects-section'>
				<div className='hero-heading-box'>
					<h2 className='hero-heading-smaller'>Projects</h2>
				</div>
				<div className='projects-container'>
					<ProjectWotsch url={'projects/wotsch'} />
					<ProjectRosa url={'projects/rosa'} />
					<ProjectFernweh url={'projects/fernweh'} />
				</div>
			</section>
		</section>
	);
};

export default Qualifications;
